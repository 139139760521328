import React, { useEffect } from 'react'

import ProtectedContent from './protected-content'

import styled from 'styled-components'

import { useQuery } from '@apollo/react-hooks'

import { Container } from './styles'

import gql from 'graphql-tag'

const CURRENT_USER = gql`
    query CurrentUser {
        myProfile {
            _id
            email
            roles {
                name
                permissions
            }
        }
    }
`

const STATS = gql`
    query Stats {
        stats {
            countOfBusinessCards
        }
    }
`

const Cards = styled.div`
    display: flex;
    padding-bottom: 0.5rem;
`

const Card = styled.div`
    padding: 1rem;
    margin: 0.5rem 0.5rem 0 0;
    border: 2px solid ${props => props.theme.gray3};
    text-align: center;
`

const CardTitle = styled.div``

const CardValue = styled.div`
    font-size: 2rem;
    font-weight: 800;
`

const PageTitle = styled.h1`
    color: ${props => props.theme.dark};
    margin-bottom: 0.5rem;
`
export default () => {
    const { data } = useQuery(CURRENT_USER)
    const { data: statsData } = useQuery(STATS)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (!window.localStorage.token) {
                window.location = '/login'
            }
        }
    }, [])

    return (
        <ProtectedContent
            user={data && data.myProfile}
            permission="VIEW_DASHBOARD"
        >
            <Container>
                <PageTitle>Admin area</PageTitle>
                <Cards>
                    <Card>
                        <CardTitle>Business Cards</CardTitle>
                        <CardValue>
                            {statsData && statsData.stats.countOfBusinessCards}
                        </CardValue>
                    </Card>
                    <Card>
                        <CardTitle>Business Cards</CardTitle>
                        <CardValue>
                            {statsData && statsData.stats.countOfBusinessCards}
                        </CardValue>
                    </Card>
                </Cards>
            </Container>
        </ProtectedContent>
    )
}
