import { isEmpty } from 'lodash-es'

export default ({ user, permission, children }) => {
    console.log({ user, permission })
    if (isEmpty(user)) {
        return null
    }

    if (isEmpty(user.roles)) {
        return null
    }

    const permissions = user.roles.reduce((result, role) => {
        if (isEmpty(role.permissions)) {
            return result
        }
        return result.concat(role.permissions)
    }, [])

    const can = !!permissions.find(p => permission === p)

    if (can) {
        return children
    }

    return null
}
